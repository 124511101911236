import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { graphql, Link } from "gatsby";

// Local imports
import Layout from "src/layouts/LayoutWhiteHeader";
import StripPrimary from "src/components/generic/StripPrimary/StripPrimary";
import StripTitle from "src/components/generic/StripTitle/StripTitle";
import StripsAlternatingContent from "src/components/generic/StripsAlternatingContent/StripsAlternatingContent";
import StripTwoColumnIconSmall from "src/components/generic/StripTwoColumnIconSmall/StripTwoColumnIconSmall";
import SEO from "src/components/generic/SEO/SEO";
import config from "data/SiteConfig";
import Tabs from "src/components/generic/Tabs/Tabs";

import { REPORT_DATA } from "src/routesConstants";

// Styles
// import styles from "./index.module.scss";

// Icons
import {
	FaLayerGroup,
	FaProjectDiagram,
	FaChartLine,
	FaClock
} from "react-icons/fa";

const stripPrimary = {
	title: "Working with agencies",
	subTitle: (
		<React.Fragment>
			<p>
				You&apos;ve got strategies to make, audit actions to implement
				for your clients sites all while juggling communications and
				getting ready for the quarterly review. You don&apos;t have time
				to spend 4 hours debugging a data issue.
			</p>
			<p>
				We take on the responsibility for your data and let you get on
				with the actual work you need to do.
			</p>
			<p>
				We have two different ways of working with agencies. Summary
				below, if you&apos;d like to hear more details please get in
				touch.
			</p>
		</React.Fragment>
	)
};

const agencyAgreements = [
	{
		subTitle: "Agency owns the data",
		content: (
			<React.Fragment>
				<p>
					Here we&apos;re providing data services directly to an
					agency. We have a per client agreement with the agency and
					all the data lives with the agency.
				</p>
				<p>
					The primary restriction here is we can only offer this (at
					competitive per client rates) for data sources where there
					is low customisation. e.g. search console, ranking & site
					speed data.
				</p>
				<p>
					If individual clients need a lot of custom work e.g. complex
					analytics implementations, logs etc then we tend to use the
					second model.
				</p>
			</React.Fragment>
		),
		image: null
	},
	{
		subTitle: "Customer owns the data",
		content: (
			<React.Fragment>
				<p>
					Here we&rsquo;re managing data in the customers warehouse
					and working with the agency.
				</p>
				<p>
					We do this when there are complex data sources (Logs, custom
					analytics set-ups.) or complex data requirements.
				</p>
				<p>
					In this case we have a contract with the end customer and a
					20% revenue share with the agency.
				</p>
				<p>
					The agency handles the customer relationship and we handle
					the data.
				</p>
				<p>
					<strong>Agencies get:</strong> access to best in class
					reporting and data that they might not have.
				</p>
				<p>
					<strong>Piped Out gets:</strong> Happy customers whose data
					is being used to full effect because the agency is using it
					day to day. Less account management because the agency
					handles it.
				</p>
			</React.Fragment>
		),
		image: null
	}
];

const propTypes = {
	data: PropTypes.shape().isRequired
};

const contentListFour = [
	{
		subTitle: "Lots of clients. Lots of data.",
		content: (
			<React.Fragment>
				<p>
					Lots of clients means lots of data and someone needs to own
					it.
				</p>
				<ul>
					<li>Do you have a consistent client onboarding?</li>
					<li>Do you have the client data ready when you need it?</li>
					<li>Do you need help understanding the data?</li>
					<li>Does your team constantly run into data issues?</li>
				</ul>
				<p>We help agencies get a handle on their data.</p>
			</React.Fragment>
		)
	},
	{
		subTitle: "High quality automated reports for your clients",
		content: (
			<React.Fragment>
				<p>
					We can build out specific reports to scale across your
					clients, whether they&apos;re custom or templated.
				</p>
				<p>
					We&apos;ve got years of experience in SEO and have a library
					of high quality reports for common SEO tasks. You can find
					the
					<a href={REPORT_DATA} target="_blank" rel="noreferrer">
						{" "}
						anonymised reports here.
					</a>
				</p>
			</React.Fragment>
		)
	},
	{
		subTitle: "Complex or very large client data",
		content: (
			<React.Fragment>
				<p>
					Not sure how you&apos;re going to handle a prospective
					clients data?
				</p>
				<p>
					It is too big, GBs of data that you don&apos;t know how to
					work with?
				</p>
				<p>
					Is it too complex? Log data or GA4 set-ups with custom
					session definitions and attribution.
				</p>
				<p>
					We can help you handle the data problems you&apos;d normally
					keep away from.
				</p>
			</React.Fragment>
		)
	},

	{
		subTitle: "Spend your time elsewhere",
		content: (
			<React.Fragment>
				<p>
					Setting up and maintaining the data is fiddly complex work.
				</p>
				<p>
					It&apos;s hard to hire for and when it&apos;s done your
					reward is to start the work you actually wanted to do.
				</p>
				<p>
					We handle it all for you and let you get on with your actual
					work.
				</p>
			</React.Fragment>
		)
	}
];

const Index = ({ data }) => {
	// Set images
	agencyAgreements[1].image = data.agency1.childImageSharp.fluid;
	agencyAgreements[0].image = data.agency2.childImageSharp.fluid;

	// Set images
	contentListFour[0].image = data.multipleSubfolders.childImageSharp.fluid;
	contentListFour[1].image = data.reports.childImageSharp.fluid;
	contentListFour[2].image = data.bigquerySize.childImageSharp.fluid;
	contentListFour[3].image = data.dataDiagram.childImageSharp.fluid;

	const agencyStrips = (
		<StripsAlternatingContent stripList={contentListFour} />
	);
	const tabStripProblems = [
		{
			id: "problems",
			header: "How do we help?",
			content: agencyStrips
		}
	];

	// Set-up tab content.

	const title = "Working with agencies";
	const description =
		"You've got strategies to make, actions to change on your clients site, all while juggling communications and getting ready for the quarterly review. You don't have time to spend debugging a data issue for 4 hours.";
	return (
		<Layout>
			<Helmet title={config.siteTitle} />
			<SEO pageTitle={title} pageDescription={description} />
			<StripPrimary
				title={stripPrimary.title}
				subTitle={stripPrimary.subTitle}
				fullSized={true}
				isWhite={true}
				demoButton={true}
			/>
			<section>
				{/* <StripTitle title="What do we load?" /> */}
				{/* <StripsAlternatingContent
					stripList={agencyAgreementsStrip}
					classes="m-t-lg"
					noBotMargin={true}
				/> */}
				<StripsAlternatingContent
					stripList={agencyAgreements}
					classes="m-t-lg"
					noBotMargin={true}
				/>
				<section>
					<StripTitle title="How do we help agencies?" />
					<Tabs tabData={tabStripProblems} />
				</section>
			</section>
		</Layout>
	);
};

Index.propTypes = propTypes;

export default Index;

// The graphql query made here will automatically be entered as the
// prop data in data
export const pageQuery = graphql`
	query AgencyQuery {
		# This gets the main image
		primaryImage: file(
			relativePath: {
				eq: "images/pages/homepage/homepage_stripprimary.jpg"
			}
		) {
			childImageSharp {
				fluid(maxWidth: 935, maxHeight: 575) {
					...GatsbyImageSharpFluid
				}
			}
		}
		# Get us the main alternating images
		agency1: file(
			relativePath: { eq: "images/pages/use_cases/agency1.jpg" }
		) {
			childImageSharp {
				fluid(maxWidth: 820, maxHeight: 762) {
					...GatsbyImageSharpFluid
				}
			}
		}
		agency2: file(
			relativePath: { eq: "images/pages/use_cases/agency2.jpg" }
		) {
			childImageSharp {
				fluid(maxWidth: 820, maxHeight: 762) {
					...GatsbyImageSharpFluid
				}
			}
		}
		multipleSubfolders: file(
			relativePath: {
				eq: "images/pages/how_does_it_work/multiple_subfolders.jpg"
			}
		) {
			childImageSharp {
				fluid(maxWidth: 431, maxHeight: 570) {
					...GatsbyImageSharpFluid
				}
			}
		}
		reports: file(
			relativePath: { eq: "images/pages/use_cases/reports.jpg" }
		) {
			childImageSharp {
				fluid(maxWidth: 920, maxHeight: 600) {
					...GatsbyImageSharpFluid
				}
			}
		}
		bigquerySize: file(
			relativePath: { eq: "images/pages/homepage/bigquery_size.jpg" }
		) {
			childImageSharp {
				fluid(maxWidth: 482, maxHeight: 247) {
					...GatsbyImageSharpFluid
				}
			}
		}
		dataDiagram: file(
			relativePath: { eq: "images/pages/data-diagram.jpg" }
		) {
			childImageSharp {
				fluid(maxWidth: 933, maxHeight: 658) {
					...GatsbyImageSharpFluid
				}
			}
		}
	}
`;
